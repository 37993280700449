import { template as template_eb13ee85a8cf47b4b536e79e87a4300c } from "@ember/template-compiler";
const FKText = template_eb13ee85a8cf47b4b536e79e87a4300c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
