import { template as template_5750c2695171499eae771a50af2fef22 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5750c2695171499eae771a50af2fef22(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
