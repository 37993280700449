import { template as template_14ec86bca64a4521a4d704b7a2ce8aed } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_14ec86bca64a4521a4d704b7a2ce8aed(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
